import React, { useEffect, useState } from "react";
import { postAddShare } from "../libs/api/profiling";

type Props = {
  url_token: string;
  url: string;
  text: string;
  title: string;
  isFacebook: () => boolean;
};

const ForceShareBtn: React.VFC<Props> = ({
  url_token,
  url,
  text,
  title,
  isFacebook,
}) => {
  const onShareClick = async () => {
    const res = await postAddShare(url_token);
    if (typeof gtag == "function") {
      gtag("event", "share", { event_label: title });
    }
  };
  if (isFacebook()) {
    return (
      <>
        <div className="mt-2">
          <a
            onClick={() => onShareClick()}
            className="btn bg-facebook text-white btn-block btn-lg share"
            href={`https://www.facebook.com/share.php?u=${url}&quote=詳しい結果はこちら↓`}
            rel={"nofollow noopener noreferrer"}
            target={"_blank"}
          >
            <i className="fab fa-facebook-square"></i>シェアして結果を見る
          </a>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="mt-2">
          <a
            onClick={() => onShareClick()}
            className="btn bg-twitter text-white btn-block btn-lg share"
            href={`https://twitter.com/share?url=${url}&text=${text}`}
            rel={"nofollow noopener noreferrer"}
            target={"_blank"}
          >
            <i className="fab fa-twitter mr-2"></i>ツイートして結果を見る
          </a>
        </div>
      </>
    );
  }
};
export default ForceShareBtn;
