import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from "react";

type Props = {
  title: string;
  body: string;
  klass: string;
  gradient: boolean;
};

import { postUserActionLog } from "../libs/api/user_action_log";

const ResultBody: React.VFC<Props> = ({ title, body, klass, gradient }) => {
  const wrapperClassName = () => {
    if (gradient) {
      return `gradientBody`;
    }
  };
  const logAction = async () => {
    await postUserActionLog("click_read_more");
  };
  const scroll = (e) => {
    e.preventDefault();
    var element = document.documentElement;
    var bottom = element.scrollHeight - element.clientHeight;
    window.scroll(0, bottom);
  };
  return (
    <>
      {body != null && (
        <div className={`mb-4 bg-white`}>
          <div className="p-3 text-center mb-1 rounded-top-lg contentTitle">
            <h2>{title}</h2>
          </div>
          <div className={wrapperClassName()}>
            <div
              className="bg-white p-3 rounded-bottom pb-5 contentBody"
              dangerouslySetInnerHTML={{ __html: body }}
            ></div>
            {gradient && (
              <div className="text-center readMoreBtn">
                <a
                  href="#"
                  className="btn btn-primary"
                  onClick={(e) => {
                    logAction();
                    scroll(e);
                  }}
                >
                  続きを見る
                </a>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default ResultBody;
