import React, { useContext, useEffect, useState } from "react";
import { Profiling } from "../interfaces/profiling";

type Props = {
  profiling: Profiling;
  submit: () => void;
};

const SubmitProfilingBtn: React.VFC<Props> = ({ profiling, submit }) => {
  return (
    <>
      <div className={"mb-5 " + (profiling.paid_flag ? "" : "mt-250")}>
        <button
          className="col-md-4 offset-md-4 btn btn-primary btn-lg btn-block"
          onClick={() => submit()}
        >
          送信する
        </button>
      </div>
    </>
  );
};
export default SubmitProfilingBtn;
