import React , { useState } from "react"
import Slider, { Range } from 'rc-slider';

type Props = {
  personalTendency: any,
  index: number,
  leftPoint: number
}

const PersonalTendencyPoint: React.VFC<Props> = ({ personalTendency, index, leftPoint }) => {
  const [sliderValue, setSliderValue] = useState<number>(leftPoint);

  return (
    <>
      <label className="text-center w-100">
        {personalTendency.title}
      </label>
      <div className="d-flex text-center mb-4">
        <div className="w-25">
          {personalTendency.left_side}:
          {sliderValue}
        </div>
        <Slider
        railStyle={{ backgroundColor: '#eee', height: 10 }}
        handleStyle={{
          height: 16,
          width: 16,
          marginTop: -2,
          backgroundColor: '#80CF93',
          borderColor: '#80CF93',
        }}
        trackStyle={{ backgroundColor: '#80CF93', height: 10 }}
        onChange={(val)=>setSliderValue(val)}
        defaultValue={leftPoint}
        />
        <div className="w-25">
          {personalTendency.right_side}:
          {100-sliderValue}
        </div>
        <input type="hidden" name={`result[personal_tendencies_attributes][${index}][personal_tendency_id]`} value={personalTendency.id} />
        <input type="hidden" name={`result[personal_tendencies_attributes][${index}][left_point]`} value={sliderValue} />
      </div>
    </>
  )
}
export default PersonalTendencyPoint
