import axios from 'axios';

const getClient = () => {
  const client = axios.create({
    baseURL: "/v1/",
  });

  const onSuccess = (response) => response;

  const onError = (err) => {
    if (err.response.status === 401) {
      alert('セッションがタイムアウトしました。ログインし直してください。');
      location.href = '/';
    }
    return Promise.reject(err);
  };

  client.interceptors.response.use(onSuccess, onError);

  return client;
};

export default getClient;
