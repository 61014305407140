import React, { useEffect, useState } from "react";

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

type Props = {
  labels: [];
  datas: [];
};

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const RadarChart: React.VFC<Props> = ({ labels, datas }) => {
  useEffect(() => {}, []);

  const chart_data = {
    labels: labels,
    datasets: [
      {
        label: "適性診断結果",
        data: datas,
        //backgroundColor: 'rgb(0 98 183 / 80%)',
        backgroundColor: "rgb(68 115 156 / 80%)",
        //borderColor: 'rgb(0 98 183 / 100%)',
        borderColor: "rgb(68 115 156 / 100%)",
        borderWidth: 2,
      },
    ],
  };

  const graphOption = {
    scales: {
      r: {
        suggestedMin: 0,
      },
    },
  };

  return (
    <>
      <Radar data={chart_data} options={graphOption} />
    </>
  );
};
export default RadarChart;
