import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import InputMask from "react-input-mask";

type Props = {
  buttonText: string;
  title: string;
  amount: number;
  stripeCustomerId: string;
  redirectUrl: string;
  targetProfilingId: string;
};

import { postCreateCharge } from "../../libs/api/user";
import { postUserActionLog } from "../../libs/api/user_action_log";

const PaymentModal: React.VFC<Props> = ({
  buttonText,
  title,
  amount,
  stripeCustomerId,
  redirectUrl,
  targetProfilingId,
}) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    await postUserActionLog("open_card_modal");
    setShow(true);
  };

  const [cardNumber, setCardNumber] = useState(null);
  const [expMonth, setExpMonth] = useState(null);
  const [expYear, setExpYear] = useState(null);
  const [cvc, setCvc] = useState(null);

  useEffect(() => {
    if (show == false) {
      return;
    }
  }, [show]);

  const submit = async () => {
    await postUserActionLog("submit_card");
    setLoading(true);
    if (stripeCustomerId == null) {
      const res = await postCreateCharge({
        cardNumber: cardNumber,
        cardExpMonth: expMonth,
        cardExpYear: expYear,
        cardCvc: cvc,
        amount: amount,
        targetProfilingId: targetProfilingId,
      });
      if (res.data.error) {
        alert(res.data.error);
        setLoading(false);
      } else {
        location.href = redirectUrl;
      }
    } else {
      const res = await postCreateCharge({
        amount: amount,
        targetProfilingId: targetProfilingId,
      });
      if (res.data.error) {
        alert(res.data.error);
        setLoading(false);
      } else {
        location.href = redirectUrl;
      }
    }
  };

  const setExp = (val) => {
    let [month, year] = val.split("/");
    if (year != undefined) {
      year = year.replaceAll("_", "");
      setExpYear(year);
    }
    if (month != undefined) {
      month = month.replaceAll("_", "");
      setExpMonth(month);
    }
  };

  const modalBody = () => {
    if (loading) {
      return (
        <>
          <div className="text-center">ただいま決済中です...</div>
        </>
      );
    } else {
      return (
        <>
          <table className="table table-striped mb-2">
            <tbody>
              <tr>
                <th>商品名</th>
                <td>精密AI脳科学診断</td>
              </tr>
              <tr>
                <th>金額</th>
                <td>{amount}円</td>
              </tr>
            </tbody>
          </table>
          {stripeCustomerId == null && (
            <>
              <div className="callout callout-info">
                クレジットカードを追加してください
              </div>
              <div id="creditcard">
                <p>対応カード</p>
                <i className="fab fa-cc-visa mr-2"></i>
                <i className="fab fa-cc-mastercard mr-2"></i>
                <i className="fab fa-cc-amex mr-2"></i>
                <i className="fab fa-cc-jcb mr-2"></i>
                <i className="fab fa-cc-diners-club mr-2"></i>
                <i className="fab fa-cc-discover"></i>
                <div className="form-group card-number">
                  <label className="control-label" htmlFor="cc_number">
                    カード番号
                  </label>
                  <div className="row">
                    <div className="col-sm-12">
                      <input
                        className="form-control"
                        defaultValue={cardNumber}
                        id="card-number"
                        onChange={(e) => setCardNumber(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group card-exp">
                      <label className="control-label" htmlFor="cc-exp">
                        有効期限(MM/YY)
                      </label>
                      <InputMask
                        className="form-control"
                        placeholder="MM/YY"
                        defaultValue={`${expYear}/${expMonth}`}
                        id="card-exp"
                        mask="99/99"
                        onChange={(e) => setExp(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group card-cvc">
                      <label className="control-label" htmlFor="card-cvc">
                        セキュリティコード
                      </label>
                      <input
                        className="form-control"
                        id="card-cvc"
                        defaultValue={cvc}
                        onChange={(e) => setCvc(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
  };

  return (
    <>
      <Button variant="success" className="btn-block" onClick={handleShow}>
        {buttonText}
      </Button>

      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody()}</Modal.Body>
        {!loading && (
          <Modal.Footer>
            <Button variant="primary" onClick={() => submit()}>
              送信
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};
export default PaymentModal;
