import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
Rails.start()
ActiveStorage.start()
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
require("trix")
require("@rails/actiontext")
document.addEventListener('DOMContentLoaded', () => {
  require("../vendor/adminlte")
})
