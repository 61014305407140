import getClient from './client';

/**
 * S3 presign
 */
export const postProfilingAnswer = (params) => {
  return getClient().post(`/profilings/calculate`, params);
};

/**
 * S3 presign
 */
 export const postAddShare = (url_token) => {
  return getClient().post(`/profilings/add_share_count`, {url_token: url_token});
};