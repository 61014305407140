import React, { useEffect, useState } from "react";

type Props = {
  message: string;
  onClose: () => void;
};

const Flash: React.VFC<Props> = ({ message, onClose }) => {
  return (
    <div className="alert alert-danger alert-dismissible">
      <button type="button" className="close" onClick={() => onClose()}>
        ×
      </button>
      <h5 className="mb-0">
        <i className="icon fas fa-ban"></i>
        {message}
      </h5>
    </div>
  );
};
export default Flash;
