import React, { useContext, useEffect, useState } from "react";
import { Profiling } from "../interfaces/profiling";
import ShowQuestion from "./ShowQuestion";
import SubmitProfilingBtn from "./SubmitProfilingBtn";
import ForceShareBtn from "./ForceShareBtn";
import { postProfilingAnswer } from "../libs/api/profiling";
import AdSense from "react-adsense";

type Props = {
  profiling: Profiling;
  rectangle_ads: [];
};

const CreateProfiling: React.VFC<Props> = ({ profiling, rectangle_ads }) => {
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resultUrl, setResultUrl] = useState(null);
  const [resultText, setResultText] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const params = window.location.search;
  const query = new URLSearchParams(params);
  useEffect(() => {
    if (currentPage != null) {
      if (currentPage == 1) {
        localStorage.removeItem(profiling.url_token + "_profiling");
      } else {
        const currentAnswers = localStorage.getItem(
          profiling.url_token + "_profiling"
        );
        if (currentAnswers != null) {
          setAnswers(JSON.parse(currentAnswers));
        }
      }
    }
    setCurrentPage(query.get("page") != null ? parseInt(query.get("page")) : 1);
  }, [currentPage]);

  const handleAnswerSelect = (answered, question_id) => {
    setAnswers((currentAnswer) =>
      setAnswersCallback(currentAnswer, question_id, answered)
    );
    const y = window.pageYOffset + 200;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const setAnswersCallback = (currentAnswers, question_id, answered) => {
    const answerByIdx = currentAnswers.find(
      (answer) => answer.question_id == question_id
    );
    var newAnswers;
    if (answerByIdx === undefined) {
      newAnswers = [
        ...currentAnswers,
        { question_id: question_id, answered: answered },
      ];
    } else {
      newAnswers = currentAnswers.map((currentAnswer) => {
        if (currentAnswer.question_id == question_id) {
          return { idx: question_id, answered: answered };
        } else {
          return currentAnswer;
        }
      });
    }
    //localStorage.setItem(profiling.url_token + "_profiling", JSON.stringify(newAnswers));
    return newAnswers;
  };

  const handleSubmit = async () => {
    if (answers.length >= profiling.question_count) {
      setLoading(true);
      const res = await postProfilingAnswer({
        answers: answers,
        url_token: profiling.url_token,
      });
      if (profiling.force_share) {
        setLoading(false);
        setResultUrl(res.data.url);
        setResultText(res.data.text);
      } else {
        location.href = res.data.url;
      }
      localStorage.removeItem(profiling.url_token + "_profiling");
    } else {
      alert(
        `質問にすべて回答してください。現在 ${answers.length} / ${profiling.question_count} 回答`
      );
    }
  };

  const pagenateQuestions = () => {
    return profiling.questions.slice(
      (currentPage - 1) * profiling.question_per_page,
      (currentPage - 1) * profiling.question_per_page +
        profiling.question_per_page
    );
  };

  const isLastPage = () => {
    if (profiling.question_count / profiling.question_per_page == currentPage) {
      return true;
    } else {
      return false;
    }
  };

  const nextPage = () => {
    if (answers.length >= profiling.question_per_page * currentPage) {
      localStorage.setItem(
        profiling.url_token + "_profiling",
        JSON.stringify(answers)
      );
      if (profiling.paid_flag) {
        location.href = `/profilings/${profiling.url_token}/paid?page=${
          currentPage + 1
        }`;
      } else {
        location.href = `/profilings/${profiling.url_token}?page=${
          currentPage + 1
        }`;
      }
    } else {
      alert(
        `質問にすべて回答してください。現在 ${answers.length} / ${
          profiling.question_per_page * currentPage
        } 回答`
      );
    }
  };

  const totalIdx = (idx) => {
    return (currentPage - 1) * profiling.question_per_page + idx;
  };

  if (currentPage == null) {
    return <>loading...</>;
  }

  const calcLeft = () => {
    return (answers.length / profiling.questions.length) * 100;
  };

  const isFacebook = () => {
    return window.navigator.userAgent.indexOf("FBAN") > 0;
  };

  if (resultUrl != null) {
    return (
      <>
        <div
          className="modal fade show profilingResult"
          style={{ display: "block" }}
          id="modal-default"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">解析が完了しました！</h4>
              </div>
              <div className="modal-body">
                <div className="callout callout-green text-center text-sm">
                  {isFacebook() ? (
                    <h5 className="mb-0">
                      <i className="fa fa-check-circle text-green mr-2"></i>
                      続きはFacebookでシェアすると見れます
                    </h5>
                  ) : (
                    <h5 className="mb-0">
                      <i className="fa fa-check-circle text-green mr-2"></i>
                      続きはツイッターで見れます
                    </h5>
                  )}
                </div>
                <div className="mt-2">
                  <ForceShareBtn
                    url={resultUrl}
                    url_token={profiling.url_token}
                    text={resultText}
                    title={profiling.title}
                    isFacebook={isFacebook}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </>
    );
  }

  return (
    <>
      {loading && (
        <div id="fullOverlay">
          <div className="loading">結果を解析しています...</div>
          <div className="loader">結果を解析しています...</div>
        </div>
      )}
      <span className="irs irs--flat js-irs-0">
        <span className="irs">
          <span className="irs-line"></span>
          <span className="irs-max">{profiling.questions.length}問</span>
          <span className="irs-to" style={{ left: `${calcLeft()}%` }}>
            {answers.length}回答済
          </span>
        </span>
        <span className="irs-grid"></span>
        <span className="irs-bar" style={{ width: `${calcLeft()}%` }}></span>
      </span>
      {pagenateQuestions().map((question, idx) => (
        <>
          <ShowQuestion
            question={question}
            idx={totalIdx(idx)}
            rectangleAds={rectangle_ads}
            key={question.id}
            selectAnswer={handleAnswerSelect}
          />
          {idx != 0 && (idx + 1) % 3 == 0 && !profiling.no_ad_flag && (
            <AdSense.Google
              client="ca-pub-7510683172781493"
              slot="7113458323"
              format="auto"
              responsive="true"
            />
          )}
        </>
      ))}
      {isLastPage() && (
        <>
          <SubmitProfilingBtn profiling={profiling} submit={handleSubmit} />
        </>
      )}
      {!isLastPage() && (
        <>
          <div className={"mb-5 " + (profiling.paid_flag ? "" : "mt-250")}>
            <button
              className="col-md-6 offset-md-3 btn btn-primary btn-lg btn-block"
              onClick={() => nextPage()}
            >
              次のページへ
            </button>
          </div>
        </>
      )}
    </>
  );
};
export default CreateProfiling;
