import React, { useContext, useEffect, useState } from "react";
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();
import { Answer } from "../interfaces/answer";

type Props = {
  answer: Answer;
  question_id: string;
  idx: number;
  select: (answered, question_id) => void;
};

const EnterAnswer: React.VFC<Props> = ({
  answer,
  question_id,
  idx,
  select,
}) => {
  const [answered, setAnswered] = useState<string>("");

  const selectAnswer = (str) => {
    setAnswered(str);
    select(str, question_id);
  };

  return (
    <>
      <ul className="answer_row mb-5">
        <li className="answer_item">
          <label
            className={
              "answer_label " + (answered == "agree" ? "answered" : "")
            }
            onClick={() => selectAnswer("agree")}
          ></label>
          <div className="text-muted text-xs">{answer.agree}</div>
        </li>
        <li className="answer_item">
          <label
            className={
              "answer_label " + (answered == "slight_agree" ? "answered" : "")
            }
            onClick={() => selectAnswer("slight_agree")}
          ></label>
          <div className="text-muted text-xs">{answer.slight_agree}</div>
        </li>
        <li className="answer_item">
          <label
            className={
              "answer_label " + (answered == "subtle" ? "answered" : "")
            }
            onClick={() => selectAnswer("subtle")}
          ></label>
          <div className="text-muted text-xs">{answer.subtle}</div>
        </li>
        <li className="answer_item">
          <label
            className={
              "answer_label " + (answered == "slight_denial" ? "answered" : "")
            }
            onClick={() => selectAnswer("slight_denial")}
          ></label>
          <div className="text-muted text-xs">{answer.slight_denial}</div>
        </li>
        <li className="answer_item">
          <label
            className={
              "answer_label " + (answered == "denial" ? "answered" : "")
            }
            onClick={() => selectAnswer("denial")}
          ></label>
          <div className="text-muted text-xs">{answer.denial}</div>
        </li>
      </ul>
    </>
  );
};
export default EnterAnswer;
