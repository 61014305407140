import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import { Question } from "../interfaces/question";
import EnterAnswer from "./EnterAnswer";

type Props = {
  question: Question;
  idx: number;
  selectAnswer: (answered, question_id) => void;
  rectangleAds: [];
};
const ShowQuestion: React.VFC<Props> = ({
  question,
  idx,
  selectAnswer,
  rectangleAds,
}) => {
  const divRef = useRef();

  return (
    <>
      <div
        id={`show_question_${idx}`}
        className="text-center mt-4 show_question"
      >
        <h5>
          {idx + 1}.{question.title}
        </h5>
        <EnterAnswer
          answer={question.answer}
          idx={idx}
          question_id={question.id}
          select={selectAnswer}
        />
      </div>
    </>
  );
};
export default ShowQuestion;
