import React, { useEffect, useState } from "react";
import { postAddShare } from "../libs/api/profiling";

type Props = {
  url_token: string;
  url: string;
  text: string;
  title: string;
  reply_to_id: string;
};

const ShareBtns: React.VFC<Props> = ({
  url_token,
  url,
  text,
  title,
  reply_to_id,
}) => {
  const copyUrl = (event, url) => {
    event.preventDefault();
    const e = document.createElement("textarea");
    e.value = `${url}`;
    document.querySelector("body").append(e);
    e.select();
    document.execCommand("copy");
    e.remove();
    navigator.clipboard.writeText(url);
    alert("コピーしました");
  };

  const onShareClick = async () => {
    const res = await postAddShare(url_token);
    if (typeof gtag == "function") {
      gtag("event", "share", { event_label: title });
    }
  };

  const twitterShareUrl = () => {
    var r = `https://twitter.com/share?url=${url}&text=${text}&related=brain_dgs`;
    if (reply_to_id != null) {
      r = r + `&in_reply_to=${reply_to_id}`;
    }
    return r;
  };

  return (
    <>
      <div className="mt-2">
        <a
          onClick={() => onShareClick()}
          className="btn bg-twitter text-white btn-block btn-lg share"
          href={twitterShareUrl()}
          rel={"nofollow noopener noreferrer"}
          target={"_blank"}
        >
          <i className="fab fa-twitter mr-2"></i>結果をツイート
        </a>
      </div>
      <div className="row mt-2">
        <div className="col-md-8 offset-md-2">
          <div className="row narrow-col">
            <div className="col-4">
              <a
                className="btn bg-facebook text-white btn-block btn-lg share"
                href={`https://www.facebook.com/share.php?u=${url}`}
                rel={"nofollow noopener noreferrer"}
                target={"_blank"}
              >
                <i className="fab fa-facebook-square"></i>
              </a>
            </div>
            <div className="col-4">
              <a
                className="btn bg-line text-white btn-block btn-lg share"
                href={`https://social-plugins.line.me/lineit/share?url=${url}&text=${text}`}
                rel={"nofollow noopener noreferrer"}
                target={"_blank"}
              >
                <i className="fab fa-line"></i>
              </a>
            </div>
            <div className="col-4">
              <a
                className="btn bg-red text-white btn-block btn-lg"
                href="#"
                onClick={(e) => copyUrl(e, url)}
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                <i className="fa fa-link"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ShareBtns;
