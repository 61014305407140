import React, { useState } from "react";
import Slider, { Range } from "rc-slider";

type Props = {
  personalTendency: any;
  index: number;
  leftPoint: number;
};

const PersonalTendencyPoint: React.VFC<Props> = ({
  personalTendency,
  index,
  leftPoint,
}) => {
  const [sliderValue, setSliderValue] = useState<number>(leftPoint);

  const leftColor = () => {
    if (sliderValue > 50) {
      return "rgb(68 115 156)";
    } else {
      return "#ccc";
    }
  };

  const rightColor = () => {
    if (sliderValue <= 50) {
      return "rgb(68 115 156)";
    } else {
      return "#ccc";
    }
  };

  const leftFontSize = () => {
    if (sliderValue > 50) {
      return "1.25rem";
    } else {
      return "0.75rem";
    }
  };

  const rightFontSize = () => {
    if (sliderValue <= 50) {
      return "1.25rem";
    } else {
      return "0.75rem";
    }
  };

  return (
    <>
      <label className="text-center w-100 color-main">
        {personalTendency.title}
      </label>
      <div className="d-flex text-center mb-2">
        <div className="w-25">
          <div className="text-sm text-bold" style={{ color: leftColor() }}>
            {sliderValue}%
          </div>
        </div>
        <Slider
          railStyle={{ backgroundColor: rightColor(), height: 16 }}
          handleStyle={{
            height: 16,
            width: 16,
            marginTop: 0,
            marginLeft: -16,
            backgroundColor: leftColor(),
            borderColor: leftColor(),
          }}
          trackStyle={{ backgroundColor: leftColor(), height: 16 }}
          onChange={(val) => setSliderValue(val)}
          defaultValue={leftPoint}
          disabled={true}
        />
        <div className="w-25">
          <div className="text-sm text-bold" style={{ color: rightColor() }}>
            {100 - sliderValue}%
          </div>
        </div>
        <input
          type="hidden"
          name={`result[personal_tendencies_attributes][${index}][personal_tendency_id]`}
          value={personalTendency.id}
        />
        <input
          type="hidden"
          name={`result[personal_tendencies_attributes][${index}][left_point]`}
          value={sliderValue}
        />
      </div>
      <div className="d-flex text-center mb-2">
        <div className="w-50 text-left">
          <label style={{ color: leftColor(), fontSize: leftFontSize() }}>
            {personalTendency.left_side}
          </label>
        </div>
        <div className="w-50 text-right">
          <label style={{ color: rightColor(), fontSize: rightFontSize() }}>
            {personalTendency.right_side}
          </label>
        </div>
      </div>
      {personalTendency.desc != "" && (
        <div className="bg-light mb-4 p-2 text-xs">{personalTendency.desc}</div>
      )}
    </>
  );
};
export default PersonalTendencyPoint;
